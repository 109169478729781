
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { designation_list } from "@/core/data/genericData";
import axios from "axios";
import JwtService from "@/core/services/JwtService";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "add-branch-modal",
  directives: { mask },
  props: {
    id: { required: true },
  },
  components: {},

  // ShowHide(){
  //       if(this.formData.value_select == 'add')
  //       {
  //         console.log("innnnnnnnnnnnnnnnnnnnnn");
  //         (document.querySelector("#value_lable") as HTMLInputElement).style.display = "block";
  //         const modal = new Modal(document.getElementById("newtxt"));
  //         modal.show();
  //       }
  //       else
  //       {
  //         const modal = new Modal(document.getElementById("newtxt"));
  //         modal.hide();
  //         (document.querySelector("#value_lable") as HTMLInputElement).style.display = "none";
  //       }
  // },

  setup(props) {
    const store = useStore();
    const designation_item = designation_list;
    const formRef = ref<null | HTMLFormElement>(null);
    const addAttributeModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const showAddtributeDialogue = ref<boolean>(false);

    const router = useRouter();
    const loadingData = ref<boolean>(true);
    const attribute = ref([]);
    const formData = ref({
      attribute_select: "",
      value_select: "",
      uom_select: "",
      //uom_list: "",
      new: "",
      category_id: "",
    });
    const uom_value = ref([]);
    const attr_value = ref([]);
    const OnClickAttribute = (attrid) => {
      for (var i = 0; i < attribute.value.length; i++) {
        if (attribute.value[i]["attribute_id"] == attrid) {
          attr_value.value = attribute.value[i]["attribute_options_json"];
          uom_value.value = attribute.value[i]["uom_list"];
        }
      }
      // if(formData.value.attribute_select == '')
      // {
      //   const modal = new Modal(document.getElementById("uomId")).innerHTML = '';
      // }
    };

    const rules = ref({
      attribute_select: [
        {
          required: true,
          message: "Attribute is required",
          trigger: "change",
        },
      ],
      uom_select: [
        {
          required: true,
          message: "UOM is required",
          trigger: "change",
        },
      ],
      value_select: [
        {
          required: true,
          message: "Value is required",
          trigger: "change",
        },
      ],
    });

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    onMounted(async () => {
      await getAttributeList("");
      debugger;
      console.log(formData.value["category_id"]);
    });

    const getAttributeList = async (data) => {
      loadingData.value = true;

      try {
        var values = {
          attribute_id: 0,
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
        };

        await store
          .dispatch(Actions.CUST_ADD_ATTRIBUTE_LIST, values)
          .then(({ data }) => {
            attribute.value = data.attribute_list;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setAttributeLinkData = async (data) => {
      console.log("hhhhhhhhhhh");

      var standard_id = (
        document.querySelector("input[name=std_id]") as HTMLInputElement
      ).value;
      console.log(standard_id);
      const db_data = {
        category_id: standard_id,
        attribute_id: data.attribute_select,
        attribute_value: data.value_select,
        attribute_uom_id: data.uom_select,
      };
      await store
        .dispatch(Actions.CUST_MAPPING_ATTRIBUTE, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              //loading.value = false;
              //
              Swal.fire({
                text: "Attribute has been successfully mapped.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addAttributeModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            //loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        //loading.value = false;
        return;
      }

      if (formData.value.value_select == "add") {
        formData.value.value_select = formData.value.new;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setAttributeLinkData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      attribute,
      addAttributeModalRef,
      getAttributeList,
      OnClickAttribute,
      attr_value,
      showAddtributeDialogue,
      uom_value,
    };
  },
});
