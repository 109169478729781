
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductGradeModal from "@/components/modals/forms/AddProductGradeModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { mask } from "vue-the-mask";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AddProductStandardSettings from "@/components/modals/forms/AddProductStandardSettings.vue";
import EditProductStandardSettings from "@/components/modals/forms/EditProductStandardSettings.vue";
import { Modal } from "bootstrap";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import JwtService from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import {
  active,
  input_value_required_yn,
  input_value_type,
  is_search_required_yn,
} from "@/core/data/genericData";

export default defineComponent({
  props: {
    id: { required: true },
    setting: { required: true },
  },
  name: "product-setting-list",
  directives: { mask },
  components: {
    AddProductStandardSettings,
    EditProductStandardSettings,
  },

  setup(props) {
    const loading = ref<boolean>(false);
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const searchTearm = ref("");
    const formRef = ref<null | HTMLFormElement>(null);
    const formRefdelete = ref<null | HTMLFormElement>(null);
    const user_input_data = input_value_required_yn;
    const search_data = is_search_required_yn;
    const input_type_data = input_value_type;
    const router = useRouter();
    var formDatadelete = ref({});
    const editProductStandardSettingsAttributeModalRef = ref<null | HTMLElement>(
      null
    );
    const deleteModalRef = ref<null | HTMLFormElement>(null);

    var ids = ref(props.id);
    var editid = ref(props.setting);
    interface ViewPrice {
      standard_no;
      attribute_title;
      uom_val;
      standard_attribute_setting_id;
      delimiter;
      input_value_required_yn;
      input_value_type;
      is_child;
      is_search_required_yn;
      max_value;
      min_value;
      parent_standard_attribute_setting_id;
      standard_id;
      uom_id;
      active: {
        label: string;
        color: string;
      };
    }

    var paginationData = ref({});
    var resultsView1 = ref<Array<ViewPrice>>([]);
    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getStandardList();
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getStandardList();
    };

    const attr_modal = (standard_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_product_standard_settings")
      );
      modal.show();
      (document.querySelector("#std_id") as HTMLInputElement).value =
        standard_id;
    };
    const store_id = ref([]);
    const edit_modal = async (standard_attribute_setting_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_edit_product_settings")
      );
      modal.show();
      (document.querySelector("#setting_id") as HTMLInputElement).value =
        standard_attribute_setting_id;
      editid = standard_attribute_setting_id;
      // var values = {
      //     "standard_attribute_setting_id": standard_attribute_setting_id,
      //     "standard_id" : Number(props.id),
      //     "page": parseInt(paginationData.value["activePage"]),
      //     "records_per_page": parseInt(paginationData.value["perPage"]),
      //     "search_term": "",
      //   };

      //   await store.dispatch(Actions.CUST_PRODUCT_STANDARD_SETTINGS_LIST, values).then(({ data }) => {
      //     store_id.value = data
      //   });
      console.log("editid");
      console.log(editid);
    };

    var tableData = ref<Array<ViewPrice>>([]);

    const getStandardList = async () => {
      try {
        var values = {
          //"standard_attribute_setting_id": 0,
          standard_id: Number(props.id),
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          search_term: "",
        };

        await store
          .dispatch(Actions.CUST_PRODUCT_STANDARD_SETTINGS_LIST, values)
          .then(({ data }) => {
            debugger;
            tableData.value = [];

            // set pagination
            paginationData.value["total"] = data.total_records;
            paginationData.value["start"] = data.records_from;
            paginationData.value["end"] = data.records_upto;
            paginationData.value["activePage"] = data.page;
            paginationData.value["totPage"] = data.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination

            var resultsM = ref<Array<ViewPrice>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";
            for (let j = 0; j < data.result_list.length; j++) {
              if (data.result_list[j].active) {
                active_label = "Yes";
                active_color = "success";
              } else {
                active_label = "No";
                active_color = "danger";
              }

              resultsView1.value = Array({
                standard_no: data.result_list[j].standard_no,
                attribute_title: data.result_list[j].attribute_title,
                uom_val: data.result_list[j].uom_val,
                standard_attribute_setting_id:
                  data.result_list[j].standard_attribute_setting_id,
                delimiter: data.result_list[j].delimiter,

                input_value_required_yn:
                  data.result_list[j].input_value_required_yn === 0
                    ? "No"
                    : "Yes",
                input_value_type: data.result_list[j].input_value_type,
                is_child: data.result_list[j].is_child === 0 ? "No" : "Yes",
                is_search_required_yn:
                  data.result_list[j].is_search_required_yn === 0
                    ? "No"
                    : "Yes",
                max_value: data.result_list[j].max_value,
                min_value: data.result_list[j].min_value,
                parent_standard_attribute_setting_id:
                  data.result_list[j].parent_standard_attribute_setting_id,
                standard_id: data.result_list[j].standard_id,
                uom_id: data.result_list[j].uom_id,
                active: {
                  label: active_label,
                  color: active_color,
                },
              });

              tableData.value.splice(
                j,
                resultsView1.value.length,
                ...resultsView1.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    onUpdated(() => {
      // console.log("aaap");
      // console.log(list_data);
    });

    onMounted(async () => {
      await getStandardList();
      await getAttributeList();
      
    });

    const rules = ref({
      title: [
        {
          required: true,
          message: "Attribute Title is required",
          trigger: "change",
        },
      ],
      value_required: [
        {
          required: true,
          message: "User Input Required is required",
          trigger: "change",
        },
      ],
      // active: [
      //   {
      //     required: true,
      //     message: "Required",
      //     trigger: "change",

      //   },
      // ],
    });

    const attribute_setting = ref([]);
    const getAttributeList = async () => {
      //loading.value = true;
      debugger;
      try {
        //var standard_id = ((document.querySelector("input[name=std_id]") as HTMLInputElement).value);
        var values = {
          standard_id: ids.value,
        };
        await store
          .dispatch(Actions.CUST_PRODUCT_STANDARD_PARENT_DP, values)
          .then(({ data }) => {
            attribute_setting.value = data;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const uom = ref([]);
    const setUomData = async () => {
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_ADD_UOM_LIST)
          .then(({ data }) => {
            uom.value = data;
           // loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

     const parent_value = ref([]);
    var formData = ref({});
    const setFormData = async (data) => {    
      data = data['standard_attribute_setting_id'];
        try {

       var values = { 
          standard_id: Number(props.id),
          standard_attribute_setting_id: data,
          page: 1,
          records_per_page: 1,
          search_term: "",
            }
            console.log('values')
            console.log(values)
        await store.dispatch(Actions.CUST_PRODUCT_STANDARD_SETTINGS_LIST, values).then(({ data }) => {    
          parent_value.value = data.parent_standard_attribute_setting_id
          
          formData.value = {
            id: data.standard_attribute_setting_id,
            value_required: data.input_value_required_yn,
            value_type_select: data.input_value_type,
            parent_attr: data.parent_standard_attribute_setting_id,
            parent_attr_title: data.parent_attribute_title,
            search_select: data.is_search_required_yn,
            uom_select: data.pincode_id,
            min_value: data.min_value,
            max_value: data.max_value,
            delimiter: data.delimiter,
            title: data.attribute_title,
            display_order: data.display_order,
            
            };
            console.log('formData.value')
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

     const setLinkData = async (standard_data) => {
      var user = JSON.parse(JwtService.getToken());

      var standard_id = ids.value;
      
      const db_data = {
        standard_id: Number(props.id),
        standard_attribute_setting_id: standard_data.id,
        attribute_title: standard_data.title,
        input_value_required_yn: standard_data.value_required,
        input_value_type: standard_data.value_type_select,
        min_value:
          standard_data.value_required === 1 && standard_data.min_select
            ? parseFloat(standard_data.min_select)
            : 0.0,
        max_value:
          standard_data.value_required === 1 &&
          standard_data.value_type_select === "RANGE" &&
          standard_data.max_select
            ? parseFloat(standard_data.max_select)
            : 0.0,
        display_order: standard_data.display_order || 1,
        parent_standard_attribute_setting_id: standard_data.parent_attr || parent_value.value,
        is_search_required_yn: standard_data.search_select,
        uom_id: standard_data.uom_select != "" ? 0 : standard_data.uom_select,
        delimiter: standard_data.delimiter != "" ? "" : standard_data.delimiter,
        user_id: user.user_id,
        active: 1,
      };
      
      await store
        .dispatch(Actions.CUST_UPDATE_STD_ATTR_SETTINGS, db_data)
        .then(({ data }) => {
          if (data.is_success) {
            setTimeout(() => {
              loading.value = false;
              hideModal(editProductStandardSettingsAttributeModalRef.value);
              Swal.fire({
                text: "Product Standard Attribute Settings Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editProductStandardSettingsAttributeModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

     const delete_modal = (standard_attribute_setting_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_delete")
      );
      modal.show();
      (document.querySelector("#delete_id") as HTMLInputElement).value =
        standard_attribute_setting_id;
    };

     const DeleteData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      //data = data['standard_attribute_setting_id'];
      
      const db_data = {
         "standard_id" : Number(props.id),
         "standard_attribute_setting_id": (document.querySelector('#delete_id') as HTMLInputElement).value,
         "user_id": user.user_id,
      };
      console.log('db_data')
      console.log(db_data)

      await store
        .dispatch(Actions.CUST_DELETE_ATTR_SETTINGS, db_data)
        .then(({ data }) => {
          if (data.is_success) {
            setTimeout(() => {
              loading.value = false;
              //hideModal(deleteModalRef.value);
              Swal.fire({
                text: "Record Deleted Successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;
           // hideModal(deleteModalRef.value);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setLinkData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const delete_submit = async () => {
      loading.value = true;

      if (!formRefdelete.value) {
        loading.value = false;
        return;
      }

      formRefdelete.value.validate(async (valid) => {
        if (valid) {
          await DeleteData(formDatadelete.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    
    return {
      tableData,
      formData,
      formDatadelete,
      delete_submit,
      checkedCompany,
      resultsView1,
      getStandardList,
      changePageChange,
      setUomData,
      getAttributeList,
      submit,
      delete_modal,
      uom,
      rules,
      search_data,
      setFormData,
      DeleteData,
      refreshData,
      attr_modal,
      edit_modal,
      formRef,
      formRefdelete,
      ids,
      editid,
      count,
      paginationData,
      user_input_data,
      input_type_data,
      attribute_setting,
      loadingData,
      loading,
      deleteModalRef,
      editProductStandardSettingsAttributeModalRef,
    };
  },
});
