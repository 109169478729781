
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import EditProductStandardModal from "@/components/modals/forms/EditProductStandardModal.vue";
import StandardAttributeList from "@/views/apps/product/StandardAttributeList.vue"
import ProductStandardSettingsList from "@/views/apps/product/ProductStandardSettingsList.vue"
export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "product-standard-details-listing",
  components: {
    EditProductStandardModal,
    StandardAttributeList,
    ProductStandardSettingsList
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();

    interface ViewCategories {
        standard_id,
        standard_no,
        standard_name,
        common_name,
       // no_of_lic,
        standard_title,
        standard_master_id,
        active : {
          label :string;
          color :string;
        }
      };    
    
    var resultsView = ref<ViewCategories>();
  
    resultsView.value = {
      standard_id: "",
      standard_no: "",
      standard_name: "",
      common_name:"",
      //no_of_lic: "",
      standard_title: "",
      standard_master_id: "",
      active: {
        label: "",
        color: "",
      }
    }

    const getProductStandardDetails = async () => {
      try {

      var values = { "standard_id"  :  Number(props.id), "page"  : 1, "records_per_page" : 1,}
      await store.dispatch(Actions.CUST_GET_PRODUCT_STANDARD_LIST, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data.active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }
    
        resultsView.value = {
          standard_id :  data.standard_id,
          standard_no : data.standard_no,
          standard_name: data.standard_name,
          common_name: data.common_name,
          //no_of_lic : data.no_of_lic,
          standard_title : data.standard_title,
          standard_master_id : data.standard_master_id,
          active: {
            label: active_label,
            color: active_color
          },
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
     } catch (e) {
        console.log(e);
      }
    };        
    
    onMounted( async () => {
      await getProductStandardDetails();
      setCurrentPageBreadcrumbs("Product Standard Details", ["Product Standard"]);
    });
    
    return {
      resultsView,
      ids,
    };

  }
});
