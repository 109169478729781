
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import {mask} from 'vue-the-mask'
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import { active } from "@/core/data/genericData";
import { Modal } from "bootstrap";
import ProductStandardSettingsList from "@/views/apps/product/ProductStandardSettingsList.vue";
export default defineComponent({
  name: "edit-standard-modal", 
  directives: {mask}, 
  props: {
    data: {
        type: Object
    },
    
  },
  components: {  
    ProductStandardSettingsList  
    },    
  setup(props) {    
        const active_list_data = active;

    // this.$forceUpdate();
    const router = useRouter();    
    var formData = ref({})
    const store = useStore();
    const uom = ref([]);
    const setUomData = async () => {
      try {

        await store.dispatch(Actions.CUST_ADD_UOM_LIST).then(({ data }) => {
        uom.value = data;
          
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }
    }

    const attribute_setting = ref([]);
    const getAttributeList = async () => {
     
      try {
        //var standard_id = ((document.querySelector("input[name=std_id]") as HTMLInputElement).value);
        var values = { 
          "standard_id"  : Number((document.querySelector("input[name=std_id]") as HTMLInputElement).value)
          }
        await store.dispatch(Actions.CUST_PRODUCT_STANDARD_PARENT_DP, values).then(({ data }) => {
        attribute_setting.value = data;
        console.log("valuessssssssssssssssssssss")
        console.log(values)
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    // const edit_modal = (standard_attribute_setting_id) => {
    //   const modal = new Modal(document.getElementById("kt_modal_edit_product_settings"));
    //   modal.show();
    //   (document.querySelector('#setting_id') as HTMLInputElement).value = standard_attribute_setting_id;
    // }
    const data_fetch = ref([]);
    const setFormData = async (data) => {    
        console.log('data');
        console.log(data);
        var j = 0;

        try {
        
        var values = { 
          "standard_id": Number(data.ids), 
          "standard_attribute_setting_id": data.editid,
          "page": 1, 
          "records_per_page": 1,
          "search_term" : ""
           }
         await store.dispatch(Actions.CUST_PRODUCT_STANDARD_SETTINGS_LIST, values).then(({ data }) => { 
          formData.value = {
            id : data.standard_id,
            title : data.attribute_title,
            input_value : data.input_value_required_yn ,
            input_type : data.input_value_type,
            min_select : data.min_value,
            max_select : data.max_value,
            //is_child : data.is_child,
            parent_attr : data.parent_standard_attribute_setting_id,
            uom_select : data.uom_id,
            delimiter : data.delimiter,
            active: data.active,     
            };
            console.log('formData.value')
          console.log(values)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }
   
    onMounted( async () => {      
      await setFormData(props.data);
      //await setStandardata(standard.value)
      });  
     

    
    const formRef = ref<null | HTMLFormElement>(null);
    const editProductStandardModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);    

    const rules = ref({
      
    });
    // const standard = ref([]);
    // const setStandardata = async (data) => {
    //   const db_data = {}
    //     //loading.value = true;
    //     try {
    //       await store.dispatch(Actions.CUST_GET_PRODUCT_STANDARD_MASTER_LIST, db_data).then(({ data }) => {
    //       standard.value = data.standard_list;
    //       //loading.value = false;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);        
    //     });
        
    //   } catch (e) {
    //     console.log(e);
    //   }

    // }

    const setProductStandardData = async (standard_data) => {   
      var user = JSON.parse(JwtService.getToken());      
      var stand_id = Number((document.querySelector("input[name=setting_id]") as HTMLInputElement).value);
      
      const db_data = {
            "standard_id": standard_data.id,
            "standard_attribute_setting_id" : stand_id ,
            "attribute_title" : standard_data.title,
            "input_value_required_yn": standard_data.input_value ,
            "input_value_type" : standard_data.input_type,
            "min_value" : standard_data.min_select,
            "max_value" : standard_data.max_select,
            "is_child" : standard_data.is_child,
            "parent_standard_attribute_setting_id" : standard_data.parent_attr,
            "uom_id" : standard_data.uom_select,
            "delimiter" : standard_data.delimiter,
            "user_id": user.user_id,  
            "active": standard_data.active,     
        }

        await store.dispatch(Actions.CUST_UPDATE_STD_SETTINGS, db_data).then(({ data }) => {
        console.log(data);
        
        if (data)
        {
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Product Standard Attribute Settings has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editProductStandardModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
        })
      .catch(({ response }) => {
        console.log(response);        
      });
    };

   const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setProductStandardData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });  
    }
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      //setStandardata,
      //standard,
      setUomData,
      getAttributeList,
       attribute_setting,
      //  editids,
      uom,
      editProductStandardModalRef,
      active_list_data,
    };
  },
});
