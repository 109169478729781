
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import { mask } from "vue-the-mask";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import EditProductCategoriesModal from "@/components/modals/forms/EditProductCategoriesModal.vue";
import { Modal } from "bootstrap";
import {
  active,
  input_value_required_yn,
  input_value_type,
  is_search_required_yn,
} from "@/core/data/genericData";

export default defineComponent({
  name: "add-standard-categories-modal",
  directives: { mask },
  props: {
    id: { required: true, type: Object },
    // data: {
    //     type: Object
    // }
  },

  components: {},
  // created () {
  //       const field = document.querySelector("input[name=test]").value
  //       console.log(field)
  // }

  setup(props) {
    var ids = ref(props.id.ids);
    const formRef = ref<null | HTMLFormElement>(null);
    const addProductStandardSettingsAttributeModalRef = ref<null | HTMLElement>(
      null
    );
    const loading = ref<boolean>(false);
    const active_list_data = active;
    const user_input_data = input_value_required_yn;
    const input_type_data = input_value_type;
    const search_data = is_search_required_yn;
    const loadingStandard = ref<boolean>(false);
    const router = useRouter();
    //var formData = ref({})
    const store = useStore();
    var mapping_list: any[] = [];
    const std = ref([]);
    //const inputValue = new Modal(document.getElementById("cat_id"));

    const formData = ref({
      standard_id: "",
      value_required: "",
      value_type_select: "",
      parent_attr: "",
      search_select: 0,
      uom_select: "",
      min_value: "",
      max_value: "",
      delimiter: "",
      // active: "",
      title: "",
      display_order: 1,
    });

    const uom = ref([]);
    const setUomData = async () => {
      loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_ADD_UOM_LIST)
          .then(({ data }) => {
            uom.value = data;
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const attribute_setting = ref([]);
    const getAttributeList = async () => {
      loading.value = true;
      debugger;
      try {
        //var standard_id = ((document.querySelector("input[name=std_id]") as HTMLInputElement).value);
        var values = {
          standard_id: ids.value,
        };
        await store
          .dispatch(Actions.CUST_PRODUCT_STANDARD_PARENT_DP, values)
          .then(({ data }) => {
            attribute_setting.value = data;
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setLinkData = async (standard_data) => {
      debugger;
      var user = JSON.parse(JwtService.getToken());

      var standard_id = ids.value;

      const db_data = {
        standard_id: standard_id,
        attribute_title: standard_data.title,
        input_value_required_yn: standard_data.value_required,
        input_value_type: standard_data.value_type_select,
        min_value:
          standard_data.value_required === 1 && standard_data.min_select
            ? parseFloat(standard_data.min_select)
            : 0.0,
        max_value:
          standard_data.value_required === 1 &&
          standard_data.value_type_select === "RANGE" &&
          standard_data.max_select
            ? parseFloat(standard_data.max_select)
            : 0.0,
        display_order: standard_data.display_order || 1,
        parent_standard_attribute_setting_id: standard_data.parent_attr,
        is_search_required_yn: standard_data.search_select,
        uom_id: standard_data.uom_select != "" ? 0 : standard_data.uom_select,
        delimiter: standard_data.delimiter != "" ? "" : standard_data.delimiter,
        user_id: user.user_id,
        active: 1,
      };

      await store
        .dispatch(Actions.CUST_CREATE_STD_SETTINGS, db_data)
        .then(({ data }) => {
          if (data.is_success) {
            setTimeout(() => {
              loading.value = false;
              hideModal(addProductStandardSettingsAttributeModalRef.value);
              Swal.fire({
                text: "Product Standard Attribute Settings mapped successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addProductStandardSettingsAttributeModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    onMounted(async () => {
      //await getAttributeList()
      await getAttributeList();
    });

    const rules = ref({
      title: [
        {
          required: true,
          message: "Attribute Title is required",
          trigger: "change",
        },
      ],
      value_required: [
        {
          required: true,
          message: "User Input Required is required",
          trigger: "change",
        },
      ],
      // active: [
      //   {
      //     required: true,
      //     message: "Required",
      //     trigger: "change",

      //   },
      // ],
    });

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setLinkData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      std,
      getAttributeList,
      setUomData,
      uom,
      attribute_setting,
      active_list_data,
      user_input_data,
      input_type_data,
      search_data,
      addProductStandardSettingsAttributeModalRef,
    };
  },
});
